enum Viewport {
    Mobile = "mobile",
    Tablet = "tablet",
    Desktop = "desktop"
}

function getViewport(): Viewport {
    const cw = typeof window === "undefined" ? 350 : window.document.documentElement.clientWidth;
    if(cw < 400) {
        return Viewport.Mobile;
    } else if (cw < 750) {
        return Viewport.Tablet;
    } else {
        return Viewport.Desktop;
    }
}

export default getViewport;