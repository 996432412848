import React from "react"
import { graphql } from "gatsby"
// import Layout from "../components/layout"
import LayoutTrabajo from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import Img from "gatsby-image"

import { DIMSUM } from "../styles/colors"
import { TYPOGRAPHY } from "../styles/typography"

import getViewport from "../utils/get-viewport"

const Principal = styled(LayoutTrabajo)`
    background-color: ${DIMSUM.BLACK};
    color: ${DIMSUM.WHITE};
    text-align: center;

    .portfolioItem img {
        margin: 0;
    }
`;

const TextWrapper = styled.section`
    padding: 24px;

    @media only screen and (min-width: 450px) {
        text-align: left;
        max-width: 800px;
        padding: 48px 0 48px 240px;
    }
`;

const Titulo  = styled.h1`
    
    font-family: ${TYPOGRAPHY.LUST.FONT_FAMILY};
    margin: 0;

    @supports (-webkit-touch-callout: none){
        letter-spacing: -0.05em;
    }

    &:after {
        content: " ";
        display: block;
        background-color: ${DIMSUM.YELLOW};
        height: 2px;
        width: 96px;
        margin: 22px auto 24px;

        @media only screen and (min-width: 450px) {
            margin: 24px 0;
        }
    }
`;

const Descripcion = styled.p`
    font-family: ${TYPOGRAPHY.NEUE.FONT_FAMILY};
    font-weight: ${TYPOGRAPHY.NEUE.FONT_WEIGHT.NORMAL};
    font-size: 14px;
    line-height: 20px;

    @media only screen and (min-width: 450px) {
        margin: 0;
    }
`;


const PortfolioItem = ({ data }) => {
    const { titulo, descripcion, imagenes } = data.contentfulTrabajo;
    const ImagenesArray = [];
    const viewport= getViewport();
    const destacadaSocial = imagenes[0] ? imagenes[0].social : null

    for( var i = 1; i<imagenes.length ; i++) {
        ImagenesArray.push(
            <Img 
                key={i}
                fluid={imagenes[i][viewport]}
            />
        );
    }
    return (
        <Principal
            main={false}
        >
            <SEO 
                title={titulo} 
                image={destacadaSocial}
                />
            <div className="portfolioItem">
                <Img fluid={imagenes[0][viewport]} />
                <TextWrapper>
                    <Titulo>{titulo}</Titulo>
                    <Descripcion className="description-text">{descripcion.descripcion}</Descripcion>
                </TextWrapper>
                {ImagenesArray}
            </div>
        </Principal>    );
};

export default PortfolioItem;

export const pageQuery = graphql`
    query($slug: String!) {
        contentfulTrabajo(slug: {eq: $slug }) {
            titulo
            slug
            descripcion {
                descripcion
            }
            imagenes {
                file {
                    url
                }
                mobile: fluid(quality: 90, maxWidth: 450) {
                    base64
                    aspectRatio
                    src
                    srcSet
                    srcSetWebp
                    sizes
                }
                tablet: fluid(quality: 90, maxWidth: 750) {
                    base64
                    aspectRatio
                    src
                    srcSet
                    srcSetWebp
                    sizes
                }
                desktop: fluid(quality: 90, maxWidth: 1920) {
                    base64
                    aspectRatio
                    src
                    srcSet
                    srcSetWebp
                    sizes
                }
                social: fixed(width: 1200) {
                    src
                    width
                    height
                }
            }
        }
    }
`;

/*



        */